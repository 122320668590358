const generalConstants = {
  EN: {
    HALF_YEARLY: "freightiser_half_yearly_subscription",
    QUARTER_YEARLY: "freight_search_quarter_yearly_subscription",
    MONTHLY: "freightiser_monthly_subscription",
    WEEKLY: "freightiser_weekly_subscription",
    EDITOR: "editor",
    VIEWER: "viewer",
    DISABLED: "disabled",
    SUPER: "super",
    CHANGE_PASSWORD: "Change Password",
    USER_PROFILE: "User profile",
    ADMIN_SORT_OPTIONS: ["A-Z", "Z-A"],
    ASCENDING: "ASC",
    DESCENDING: "DESC",
    SUPPORTED_PROFILE_FORMATS: ["image/jpg", "image/jpeg", "image/png"],
    PHONE_REGEX:
      "/^(?:+?(61))? ?(?:((?=.*)))?(0?[2-57-8]))? ?(dd(?:[- ](?=d{3})|(?!dd[- ]?d[- ]))dd[- ]?d[- ]?d{3})$/",
    DASH: "-",
    ACTIVE: "active",
    INACTIVE: "inactive",
    VERIFIED: "verified",
    UNVERIFIED: "unverified",
    ALL: "all",
    INVESTOR: "investor",
    AUSTRALIA: "Australia",
    GENDER_OPTIONS: [
      "male",
      "female",
      "transgender",
      "nonBinary",
      "preferNotToSay",
    ],
    WEEKDAYS: "weekdays",
    WEEKENDS: "weekends",
    BOTH: "both",
    RE_DIGIT: new RegExp(/^\d+$/),
    START_DATE_AND_TIME: "2023-02-02T10:10:00",
    END_DATE_AND_TIME: "2023-02-02T22:10:00",
    UNAUTHORIZED: "Unauthorized",
    INVALID_SIGNATURE: "Invalid signature",
    FREIGHT: "freight",
    CARRIER: "carrier",
    URGENT: "urgent",
    FREIGHTER: "freighter",
    defaultDateFormat: "DD-MM-YYYY",
    defaultStartDate: "2023/01/01",
    notAvailable: "N/A",
    GRACE: "grace",
    EXPIRED: "expired",
    MAX_FILE_SIZE: 5 * 1024 * 1024,
    ADMIN_PROFILE_UPLOAD_PREFIX: "admin/profilePhotos",
    AD_PHOTOS_PREFIX: "Ads-Photos",
  },
};
const lang = process.env.SYSTEM_LANGUAGE || "EN";

let CONST: any;

if (lang === "EN") {
  CONST = generalConstants.EN;
}

export default CONST;
