import { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import AnimateButton from "ui-component/extended/AnimateButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  selectsubscription,
  setSubscriptionStatus,
  setSubscriptionPlan,
  setFromDate,
  setToDate,
} from "store/slice/subscriptionSlice";

import dayjs, { Dayjs } from "dayjs";

import { useAppDispatch, useAppSelector } from "store/hooks";
interface Props {
  open: boolean;
  onClose: (filter?: "active" | "disabled") => void;
}

export const StatusFilterDialog = (props: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { subscriptionStatus, subscriptionPlan, filter } = useAppSelector(
    selectsubscription
  );

  const [subscriptionStatusData, setSubscriptionStatusData] = useState<
    string[]
  >(subscriptionStatus);

  const [subscriptionPlanData, setSubscriptionPlanData] = useState<string[]>(
    subscriptionPlan
  );

  useEffect(() => {
    setSubscriptionPlanData(subscriptionPlan);
    setSubscriptionStatusData(subscriptionStatus);
  }, [subscriptionPlan, subscriptionStatus]);

  console.log("sunscriotionstattus", subscriptionPlan, subscriptionPlanData);

  const [fromDateLocal, setFromDateLocal] = useState<string | null>(null);

  const [toDateLocal, setToDateLocal] = useState<string | null>(null);

  const [fromDateLocalNext, setFromDateLocalNext] = useState<string | null>(
    null
  );

  const [toDateLocalNext, setToDateLocalNext] = useState<string | null>(null);

  let newArray;

  const handleSearch = () => {
    props.onClose(filter);
    dispatch(setSubscriptionStatus(subscriptionStatusData));
    dispatch(setSubscriptionPlan(subscriptionPlanData));
    dispatch(setFromDate(fromDateLocal));
    dispatch(setToDate(toDateLocal));
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleChangePlan = (event: any) => {
    if (subscriptionPlan.length) {
      if (subscriptionPlanData.includes(event.target.value)) {
        newArray = subscriptionPlanData.filter(function (item: string) {
          return item !== event.target.value;
        });
        setSubscriptionPlanData(newArray);
      } else {
        newArray = [...subscriptionPlanData, event.target.value];
        setSubscriptionPlanData(newArray);
      }
    } else if (subscriptionPlanData.includes(event.target.value)) {
      newArray = subscriptionPlanData.filter(function (item: string) {
        return item !== event.target.value;
      });
      setSubscriptionPlanData(newArray);
    } else {
      setSubscriptionPlanData((oldArray) => [...oldArray, event.target.value]);
    }
  };

  const handleChangeStatus = (event: any) => {
    if (subscriptionStatus.length) {
      if (subscriptionStatusData.includes(event.target.value)) {
        newArray = subscriptionStatusData.filter(function (item: string) {
          return item !== event.target.value;
        });
        setSubscriptionStatusData(newArray);
      } else {
        newArray = [...subscriptionPlanData, event.target.value];
        setSubscriptionStatusData(newArray);
      }
    } else if (subscriptionStatusData.includes(event.target.value)) {
      newArray = subscriptionStatusData.filter(function (item: string) {
        return item !== event.target.value;
      });
      setSubscriptionStatusData(newArray);
    } else {
      setSubscriptionStatusData((oldArray) => [
        ...oldArray,
        event.target.value,
      ]);
    }
  };

  const handleStartDateChange = (value: any) => {
    setFromDateLocal(value);
  };

  const handleEndDateChange = (value: any) => {
    setToDateLocal(value);
  };

  const handleStartNextPaymentDateChange = (value: any) => {
    // setFromDateLocalNext(value);
  };

  const handleEndNextPaymentDateChange = (value: any) => {
    // setToDateLocalNext(value);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={handleCancel}>
      <DialogTitle>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "28px",
            color: "#111936",
          }}
        >
          Filter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider />

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h2">Subscription Plan</Typography>
            <Container sx={{ ml: -1 }}>
              <FormControl component="fieldset">
                <RadioGroup value={subscriptionPlan} sx={{ mt: 2, mb: 1 }}>
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    value="freightiser_half_yearly_subscription"
                    control={
                      <Checkbox
                        onChange={handleChangePlan}
                        checked={subscriptionPlanData.includes(
                          "freightiser_half_yearly_subscription"
                        )}
                      />
                    }
                    label="Half Yearly"
                  />
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    value="freightiser_monthly_subscription"
                    control={
                      <Checkbox
                        onChange={handleChangePlan}
                        checked={subscriptionPlanData.includes(
                          "freightiser_monthly_subscription"
                        )}
                      />
                    }
                    label="Monthly"
                  />
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    value="freightiser_weekly_subscription"
                    control={
                      <Checkbox
                        onChange={handleChangePlan}
                        checked={subscriptionPlanData.includes(
                          "freightiser_weekly_subscription"
                        )}
                      />
                    }
                    label="Weekly"
                  />
                </RadioGroup>
              </FormControl>
            </Container>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h2">Subscription status</Typography>
            <Container sx={{ ml: -1 }}>
              <FormControl component="fieldset">
                <RadioGroup value={subscriptionStatus} sx={{ mt: 2, mb: 1 }}>
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    value="active"
                    control={
                      <Checkbox
                        onChange={handleChangeStatus}
                        checked={subscriptionStatusData.includes("active")}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    value="expired"
                    control={
                      <Checkbox
                        onChange={handleChangeStatus}
                        checked={subscriptionStatusData.includes("expired")}
                      />
                    }
                    label="Expired"
                  />
                </RadioGroup>
              </FormControl>
            </Container>
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="h2" sx={{ mb: 2 }}>
          Plan renewed date range
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Startdate"
                  value={fromDateLocal ? dayjs(fromDateLocal) : null}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Enddate"
                  value={toDateLocal ? dayjs(toDateLocal) : null}
                  onChange={(newValue) => handleEndDateChange(newValue)}
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="h2" sx={{ mb: 2 }}>
          Next payment due range
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Startdate"
                  value={fromDateLocalNext ? dayjs(fromDateLocalNext) : null}
                  onChange={(newValue) =>
                    handleStartNextPaymentDateChange(newValue)
                  }
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="datepicker-field"
                  label="Enddate"
                  value={toDateLocalNext ? dayjs(toDateLocalNext) : null}
                  onChange={(newValue) =>
                    handleEndNextPaymentDateChange(newValue)
                  }
                  sx={{
                    "& label.Mui-root": {
                      top: "0px",
                    },
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSearch}
          sx={{ padding: "12px 15px !important" }}
          disabled={
            !subscriptionStatusData.length &&
            !subscriptionPlanData.length &&
            !fromDateLocal &&
            !toDateLocal
          }
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
